import "./side-by-side-section-intro.scss";

import { ReactNode } from "react";
import { EditAttributes } from "design-system/types/types";
import cn from "clsx";

export interface SideBySideSectionIntroProps {
  variant?: "ordered" | "unordered";
  title?: ReactNode;
  text?: ReactNode;
  subtitle?: ReactNode;
  editAttributes?: {
    title?: EditAttributes;
    subtitle?: EditAttributes;
    text?: EditAttributes;
  };
}

export function SideBySideSectionIntro({
  variant,
  title,
  subtitle,
  text,
  editAttributes,
}: SideBySideSectionIntroProps) {
  const containerClass = cn(
    "hbs-global-align-full",
    "hbs-component--side-by-side-section-intro",
    "hbs-collapse-bottom-margin",
    text
      ? "hbs-component--side-by-side-section-intro--text"
      : "hbs-component--side-by-side-section-intro--no-text",
  );

  return (
    <div className={containerClass} data-region="cb__section-intro-text">
      <div className="hbs-side-by-side-section-intro__wrapper">
        <div className="hbs-side-by-side-section-intro">
          {variant === "ordered" && (
            <div className="hbs-side-by-side-section-intro__order">
              <div className="hbs-side-by-side-section-intro__order-number" />
            </div>
          )}

          <div className="hbs-side-by-side-section-intro-text__content">
            {(title || subtitle) && (
              <div className="hbs-side-by-side-section-intro-text__title">
                {title && (
                  <h2 {...editAttributes?.title} className="hbs-text-h4">
                    {title}
                  </h2>
                )}

                {subtitle && (
                  <div
                    {...editAttributes?.subtitle}
                    className="hbs-side-by-side-section-intro-text__subtitle hbs-text-h4"
                  >
                    {subtitle}
                  </div>
                )}
              </div>
            )}

            {text && (
              <div className="hbs-side-by-side-section-intro-text__content-wrapper">
                <div
                  {...editAttributes?.text}
                  className="hbs-side-by-side-section-intro-text__text"
                >
                  {text}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
