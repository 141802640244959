import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { SideBySideSectionIntroEntry } from "frontend/contentful/schema/blocks";
import { SideBySideSectionIntro } from "design-system/components/blocks/side-by-side-section-intro/side-by-side-section-intro";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";

export const ContentfulSideBySideSectionIntro =
  createContentfulComponent<SideBySideSectionIntroEntry>(
    ({ entry, createEditAttributes }) => {
      return (
        <SideBySideSectionIntro
          title={
            entry.fields.sectionTitle && (
              <RenderContentfulRichText
                document={entry.fields.sectionTitle}
                disableParagraphs
              />
            )
          }
          subtitle={
            entry.fields.sectionSubtitle && (
              <RenderContentfulRichText
                document={entry.fields.sectionSubtitle}
              />
            )
          }
          text={
            entry.fields.introText && (
              <RenderContentfulRichText
                document={entry.fields.introText}
                resetHeaders="h3"
              />
            )
          }
          variant={entry.fields.variant}
          editAttributes={{
            title: createEditAttributes({ entry, fieldId: "sectionTitle" }),
            text: createEditAttributes({ entry, fieldId: "intro" }),
          }}
        />
      );
    },
  );
